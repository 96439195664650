import React, {useEffect} from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {MoreVert} from "@mui/icons-material";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
    Paper,
    Stack,
    Tab,
    tabClasses,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs
} from "@mui/material";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

const pages = ['Scores', 'Standings', 'Calendar', 'Locations'];
const settings = ['Policies', 'History', 'Past Results', 'About Us'];
const logos = [
    { image: 'cornerstone.png', width:  400 },
    { image: 'rapids.svg', width: 600 },
    { image: 'sk.png', width: 600 },
    { image: 'usa.png', width: 300 },
    { image: 'rsd.png', width: 300 },
    { image: 'intensity.png', width: 400 }
];
const locations = [
    { name: 'Cornerstone', location: 'Cornerstone Christian Academy', address: '4745 Redman Rd, Brockport, NY 14420', map: 'https://goo.gl/maps/2Pj7Ji2YNWXMaG139' },
    { name: 'Grace', location: 'Basil Marella Park', address: '232 Affinity Lane, Greece, NY, 14616', map: 'https://goo.gl/maps/Dc7jDGwxNE5oZuFT8' },
    { name: 'Intensity', location: 'Henry Minier Field', address: '2911 State Route 352, Big Flats, NY, 14814', map: 'https://goo.gl/maps/XyeWWo9Y1QDCbzws9' },
    { name: 'Lima Christian', location: 'Lima Christian School', address: '1574 Rochester St, Lima, NY 14485', map: 'https://goo.gl/maps/qJFDGrSCDk8b2GJE6' },
    { name: 'RSD', location: 'Rochester School For the Deaf', address: '1545 St Paul St, Rochester, NY 14621', map: 'https://goo.gl/maps/HsKkWQbSVYJ3yacr6' },
    { name: 'Rapids', location: 'Grace and Truth Sports Park', address: '373 N Greece Rd, Hilton, NY 14468', map: 'https://goo.gl/maps/wBKKsQidPYqsQYN79' },
    { name: 'Scarlet Knights', location: 'Bullis Park', address: '1777 Canandaigua Rd, Macedon, NY 14502', map: 'https://goo.gl/maps/VUH1SqonHFfcwD969' },
    { name: 'Union Springs', location: 'Union Springs Academy', address: '40 Spring Street Rd, Union Springs, NY 13160', map: 'https://goo.gl/maps/GePL5FHipkHiraax5' },
    { name: 'Buffalo', location: 'Elks Lodge', address: '6791 North Canal Road, Lockport, NY 14094', map: 'https://maps.app.goo.gl/71xZt1AzxczXNHns5' }

];
const boysHistory = [
    { team: 'Rapids', victories: '9', appearances: '10' },
    { team: 'Scarlet Knights', victories: '3', appearances: '7' },
    { team: 'RSD', victories: '2', appearances: '5' },
    { team: 'Freedom Academy', victories: '1', appearances: '3' },
    { team: 'Union Springs', victories: '0', appearances: '1' },
    { team: 'Cornerstone', victories: '0', appearances: '2' }
];
const girlsHistory = [
    { team: 'Scarlet Knights', victories: '9' },
    { team: 'Rapids', victories: '8' },
    { team: 'Cornerstone', victories: '1' }
];

const shuffleLogos = () => {
    let currentIndex = logos.length;
    let randomIndex:number;
    while (currentIndex != 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [logos[currentIndex], logos[randomIndex]] = [
            logos[randomIndex], logos[currentIndex]];
    }
}

shuffleLogos();

function App() {

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const [tabValue, setTabValue] = React.useState<null | string>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleTabChange = (newValue: string) => {
        setTabValue(newValue);
        handleCloseNavMenu();
        handleCloseUserMenu();
    };

    const goHome = () => {
        shuffleLogos();
        setTabValue(null);
    }

    let logo =
        <Stack direction='row' alignItems='center'>
            <Box sx={{ paddingRight: { xs: '5px', md: '15px'}, height: { xs: '20px', md: '30px'} }}>
                <img src='wnycaaBall.png' style={{ height: '100%' }}/>
            </Box>
            <Typography
                variant="h3"
                noWrap
                sx={{
                    fontFamily: 'monospace',
                    fontWeight: 600,
                    fontSize: { xs: '2rem', md: '3rem' },
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                }}>WNYCAA</Typography>
        </Stack>

    return (

        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <div className="App">
                <AppBar position="static">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters>
                            <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                                <Stack direction='row' alignItems='center' justifyContent='flex-start' onClick={goHome}
                                       sx={{cursor: "pointer"}}> {logo} </Stack>
                                <Box sx={{
                                    flexGrow: 1,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: {xs: 'none', md: 'flex'}
                                }}>
                                    <Tabs value={tabValue} onChange={(event, newValue) => handleTabChange(newValue)}>
                                        {pages.map((page) => (
                                            <Tab
                                                value={page}
                                                label={page}/>
                                        ))}
                                    </Tabs>
                                </Box>
                            </Box>
                            <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon/>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: {xs: 'block', md: 'none'},
                                    }}
                                >
                                    {pages.map((page) => (
                                        <MenuItem key={page} onClick={() => handleTabChange(page)}>
                                            <Typography textAlign="center">{page}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                                <Stack flexGrow='1' direction='row' alignItems='center' justifyContent='center'
                                       onClick={goHome} sx={{cursor: "pointer"}}>{logo}</Stack>
                            </Box>

                            <Box sx={{
                                flexGrow: 0,
                                display: 'flex',
                                justifyContent: "flex-end",
                                flexBasis: {xs: 'auto', md: '200px'}
                            }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu}>
                                        <MoreVert fontSize='large'/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{mt: '45px'}}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} onClick={() => handleTabChange(setting)}>
                                            <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                <Stack direction='column' alignItems='center' sx={{ width: "100%", height: "100%" }}>
                    {tabValue === 'Scores' &&
                        <div style={{ width:"100%", flexGrow: '1'}}>
                            <iframe frameBorder={0} src='/Default.aspx?tabid=1158888' width='100%' height='100%'></iframe>
                        </div>
                    }
                    {tabValue === 'Standings' &&
                        <div style={{ width:"100%", flexGrow: '1'}}>
                            <iframe frameBorder={0} src='/Default.aspx?tabid=1158889' width='100%' height='100%'></iframe>
                        </div>
                    }
                    {tabValue === 'Calendar' &&
                        <div style={{ width:"100%", flexGrow: '1'}}>
                            <iframe frameBorder={0} src='/Default.aspx?tabid=1158886#/gameScheduleCalendar' width='100%' height='100%'></iframe>
                        </div>
                    }
                    {tabValue === 'Locations' &&
                        <Box sx={{paddingTop: '20px'}}>
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Team</TableCell>
                                            <TableCell>Location</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Map</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {locations.map((location) => (
                                            <TableRow
                                                key={location.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {location.name}
                                                </TableCell>
                                                <TableCell>{location.location}</TableCell>
                                                <TableCell>{location.address}</TableCell>
                                                <TableCell><a href={location.map} style={{color: 'white'}}>Map</a></TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    }
                    {tabValue === 'Policies' &&
                        <Box sx={{ textAlign: 'left', maxWidth: '900px', paddingTop: '20px', paddingLeft: { xs: '10px', md: '40px' }, paddingRight: { xs: '10px', md: '40px' } }}>
                            <Typography variant='h5' sx={{paddingBottom: '10px'}}>Western New York Christian Athletic Association WNYCAA 2020 (Official)</Typography>
                            <Typography variant='h6' sx={{paddingBottom: '10px', paddingTop: '10px'}}>PART I - Introduction</Typography>
                            <Typography variant='body1'>The Western New York Christian Athletic Association (WNYCAA) is a Christian  organization which organizes competitive youth soccer leagues, tournaments and events based upon the principles of Godly integrity, honor and fair play.&nbsp; Therefore, it is our conviction that all players, coaches and spectators should inherently be held to a high standard of sportsmanship and conduct when participating in WNYCAA events.</Typography>
                            <Typography variant='h6' sx={{paddingBottom: '10px', paddingTop: '10px' }}>PART II - Organization</Typography>
                            <Typography variant='body1' sx={{paddingBottom: '10px'}}>The WNYCAA is made up of a number of individual clubs that have teams that participate.&nbsp; For purposes of voting, each club will be given one vote regardless of the number of teams they have. The clubs that get a vote will be set each year on August 1st based on their expected participation in the upcoming season.&nbsp; A club can be removed or added based on a ⅗ vote (60% or greater) of the current clubs, or if they choose to leave on their own. (The club proposed for removal still gets a vote)&nbsp; When a club votes, they can vote for, against, or abstain from the current issue at hand.</Typography>
                            <Typography variant='body1' sx={{paddingBottom: '10px'}}>There will be a league facilitator that is responsible for organizing events and ensuring the association runs smoothly.&nbsp; The facilitator can delegate individual tasks, but is ultimately responsible for making sure they are accomplished. The facilitator is responsible for organizing and collecting votes. &nbsp; Unless specified otherwise in this document, votes are majority rule.&nbsp; A vote can be raised by any club and is administered by the facilitator. &nbsp; An existing facilitator can be removed by a ⅗&nbsp; vote.&nbsp; If the facilitator is associated with a club, that club still gets a vote unless removed as described above prior to the vote.&nbsp; If a facilitator can no longer perform the role, a new facilitator can be installed with a&nbsp; ⅗ vote of the clubs.</Typography>
                            <Typography variant='body1' sx={{paddingBottom: '10px'}}>This document can be modified with a ⅗ vote of the clubs.</Typography>
                            <Typography variant='h6' sx={{paddingBottom: '10px', paddingTop: '10px'}}>PART III - Sportsmanship</Typography>
                            <Typography variant='body1' sx={{paddingBottom: '10px'}}>It is expected that coaches will instruct and enforce good sportsmanship while assertively correcting inappropriate behaviors such as taunting, cheating, overly aggressive fouls, and showing dissension toward the authority of the referees or coaches. Officials and coaches are to enforce all sportsmanship guidelines for spectators, athletes and coaches. We will not tolerate negative statements nor actions which may jeopardize the integrity of the game and/or the safety of its participants. Let every contest reflect mutual respect.</Typography>
                            <Typography variant='body1' sx={{paddingBottom: '10px'}}>Any coach, referee, participant, or spectator can be removed from the privilege of participating in the league by a ⅗ vote.&nbsp; That person can be reinstated after a specified timeframe in the original vote, or after a ⅗ vote.</Typography>
                            <Typography variant='h6' sx={{paddingTop: '10px'}}>PART IV - Player Eligibility</Typography>
                            <ul>
                                <li>Players must be age 18 or under on June 30 of the current year to participate as a player.</li>
                                <li>Players must be at least 12 years of age to participate.</li>
                                <li>No person who currently attends, nor has graduated from a public school is eligible.</li>
                                <li>No person who plays for a college or pro team is eligible.</li>
                                <li>Players may not play on multiple WNYCAA teams, even if they belong to the same club.</li>
                                <li>Students enrolled in a private school may not play for a WNYCAA team if their school has access to a soccer team.</li>
                                <li>Players may not switch clubs during the season once the first WNYCAA season game by any team has been played.</li>
                                <li>Students enrolled at a WNYCAA school may only play for that WNYCAA team unless permission is granted by that team.</li>
                            </ul>
                            <Typography variant='h6' sx={{paddingTop: '10px'}}>PART V - Referees</Typography>
                            <ul>
                                <li>The WNYCAA uses NFHS rules unless overridden in this document.&nbsp; The referees assigned must be familiar with these rules.</li>
                                <li>Home teams shall arrange and pay the referees for their games.</li>
                                <li>If a game is cancelled due to the visiting team within 24 hours of the game, the visiting team will make arrangements to pay for the referees.</li>
                                <li>Two referees are preferred for the games.&nbsp; If only one referee is planned to be assigned to a game, the opposing team should be notified more than 24 hours in advance and offered the opportunity to pay the difference for a second referee.</li>
                                <li>If the referee(s) assigned to the game do not show, a qualified substitute may be used if mutually agreed upon by both coaches. (However, no coaches nor students should referee games, except as a last resort.)&nbsp; If substitute(s) cannot be agreed upon, the game should be rescheduled.</li>
                                <li>Referees have full authority on the field, and have permission of the WNYCAA to deal with players, coaches and fans according to NFHS rules and WNYCAA policy, including permission to eject any such persons from the game and/or field area, and/or terminate the game if necessary.</li>
                                <li>If there is ever a question or concern about the performance of a referee, the coach(es) can provide details to the facilitator, who will work with the concerned coach(es) and other teams in the league if necessary.</li>
                                <li>Referees may also provide concerns to the facilitator as well about players, coaches, or fans.</li>
                            </ul>
                            <Typography variant='h6' sx={{paddingTop: '10px'}}>PART VI - Games</Typography>
                            <ul>
                                <li>If possible, jerseys should be light colored for away games and darker color for home games.&nbsp; If two teams are dressed alike, it is the away teams' responsibility to change uniforms or wear pinnies.</li>
                                <li>Coaches shall have printed team rosters available at every contest, including Name, Jersey Number, Birth Date and Age.</li>
                                <li>Once the schedule is finalized by the facilitator, if a team wishes to make a schedule change, they should contact the opposing team at least two full days in advance and request a change. The opposing team is not obligated to accommodate them. If the game is changed, the facilitator should be notified.</li>
                                <li>Games should start at their scheduled time whenever possible. If the delay is longer than 30 minutes, and is because a team does not have enough eligible players present to field a team, that team forfeits the game. &nbsp; If the delay is for a different reason, the two coaches should agree on whether to wait or reschedule the game.</li>
                                <li>Game length will follow the NFHS rules unless otherwise agreed upon by both coaches.&nbsp; This can happen due to darkness, weather, etc.</li>
                                <li>There is no overtime in the regular league games.</li>
                                <li>A team must have at least 8 eligible players to be allowed to play a game.&nbsp; The other team is encouraged to match the number of players on the field, but is not required to do so.</li>
                                <li>If any person in authority (referee, coach, or administrator) sees lightning or hears thunder, referees must stop the game, clear the players from the field, and suspend play until a continuous 30 minute period has passed with no NEW thunder or lightning. Each time a new instance occurs, the 30 minute clock starts over.</li>
                                <li>If a game cannot be completed solely due to weather, the score at the time the game was suspended will stand if the first half has been completed.&nbsp; If not, the game will be rescheduled and replayed from the beginning.&nbsp; It is ultimately the referee(s) call if a game should be suspended for weather.</li>
                                <li>If a game that is canceled for weather cannot be rescheduled, the game will be recorded as a 0-0 tie.</li>
                                <li>If a game that is canceled due to a team not being able to play is not rescheduled, the team that caused the cancellation will forfeit the game 3-0.</li>
                                <li>If there are any issues with any circumstance surrounding a game that is not already covered in this document, a decision can be made with a vote by the clubs.</li>
                            </ul>
                            <Typography variant='h6' sx={{paddingTop: '10px'}}>PART VII - Standings and Postseason</Typography>
                            <ul>
                                <li>Teams will be given points for their regular season games as follows:
                                    <ul>
                                        <li>3 points for a win</li>
                                        <li>1 point for a tie</li>
                                        <li>0 points for a loss</li>
                                    </ul>
                                </li>
                                <li>The above points will be used to determine the regular season champion, and any seeding for postseason tournaments.&nbsp; If there is a tie, it will be decided by the following rules from top to bottom until a winner is determined:
                                    <ol>
                                        <li>Most points earned head-to-head</li>
                                        <li>Best goal differential head-to-head (max plus or minus 3 per game)</li>
                                        <li>Best goal differential league play (max plus or minus 3 per game)</li>
                                        <li>Most wins in league play</li>
                                        <li>Most shutouts in league play</li>
                                        <li>Random selection method conducted by the facilitator such as coin toss.</li>
                                    </ol>
                                </li>
                                <li>The following procedure will be used to break a tie in any game that requires a winner, such as the postseason tournament:
                                    <ol>
                                        <li>5 minute break including a normal coin toss, then a 10 minute sudden victory overtime period.</li>
                                        <li>If still tied, a 2 minute break, switch ends, then another 10 minute sudden victory period.</li>
                                        <li>If still tied, normal coin toss (winner chooses to kick 1st or 2nd). Then a 2 minute break.</li>
                                        <li>Both teams select, in writing, and in order, any 5 players to take alternating penalty shots. (Best of 5 wins)</li>
                                        <li>If still tied,repeat above with 5 different players</li>
                                        <li>If still tied, choose any 5 players (can be reused from above steps) and shoot in pairs with sudden victory (one team makes it and the other doesn't) until there is a winner.</li>
                                    </ol>
                                </li>
                            </ul>
                            <Typography variant='h6' sx={{paddingTop: '10px'}}>PART VIII - Misconduct and Consequences</Typography>
                            <ul>
                                <li>One yellow “caution” in any game.&nbsp; The player must sub out and then can then go back into the game as per NFHS rules after receiving the coach's correction and being subbed back in at the normal time.</li>
                                <li>Two yellow cards in the same game results in one “hard red”. The player will sit out the rest of the game and the team will play short . Players are not required to actually leave the bench area unless their behavior is violent or disruptive. The player will also be suspended for the next WNYCAA game.</li>
                                <li>An accumulation of four total yellows during any part of the season (which did not otherwise already lead to a red card in the same game) equals 1 “soft red”. The player is suspended for the next WNYCAA game.</li>
                                <li>If a red card is issued directly for any reason this is considered a “hard red”. The player will sit out the rest of the game and the team will play short. The player will also be suspended for the next “WNYCAA game”.</li>
                                <li>Any player receiving 3 red cards (whether soft or hard) will automatically be suspended for the remainder of the season, including any post season play.</li>
                                <li>Misconduct is anything labeled as such in NFHS rules, or any other behavior contrary to the WNYCAA’s standards in connection with a game that should be brought to the WNYCAA’s attention. (eg: parking lot confrontations, heckling, instigation or participation in violent conduct, taunting, spitting at a person, or displaying insulting, offensive, abusive language or gestures toward others in connection with a WNYCAA contest, delaying the game, etc.)&nbsp; If misconduct occurs, the offending person(s) can be suspended from the league for any amount of time as described in the sportsmanship statement above.</li>
                                <li>If a person is ejected from the field area by an official, or has already been barred from attending matches, but refuses to leave the area when a WNYCAA game is in progress, the team officials and/or referees have the authority to have these persons removed from the field by police escort if necessary. Otherwise, the game may be terminated by the officials and/or referees, and the result of the game will be determined by a ⅗ vote of the clubs.</li>
                            </ul>
                        </Box>
                    }
                    {tabValue === 'History' &&
                        <Box sx={{ textAlign: 'left', maxWidth: '900px', paddingTop: '20px', paddingLeft: { xs: '10px', md: '40px' }, paddingRight: { xs: '10px', md: '40px' } }}>
                            <Typography variant='h6'><p>League History</p></Typography>
                            <Typography variant='body1'>
                                <p>The league started just for soccer back in the mid 1980s, about 1984.  The person who got it rolling was Rob Bolton from Lima Christian.  There were only 4 or 5 teams, and all of them were coed.</p>
                                <p>The teams were Golden Heights (Cornerstone currently),  Lima (morphed into Intensity), Central Baptist from Arcade, Rochester School for the Deaf, and a team from Corning.</p>
                                <p>In the early years WNYCAA was a very informal league.  There were set schedules but we didn't always have playoffs or finals.  Best record was league champs.  The coaches nominated the team with the best Christian Attitude and other similar recognition but all very simple.</p>
                                <p>We often played teams with fewer than 11 players so we would always match numbers.  Often the coaches were the refs.</p>
                                <p>Some teams that were involved over the early years include: Central Baptist, Cornerstone (Golden Heights), Henrietta, Lima Christian, Webster Christian, Calvary Chapel (Canandiagua), Corning, Bath-FLM(now Intensity, they use to play in Kanona)and St. Joseph's Villa (now called the Villa I believe).  All playing mostly coed.</p>
                                <p>As schools grew we began to offer separate Boys and Girls teams, but many of the teams still had to play coed in order to field a team.  Homeschool teams were included along the way to make for a larger league.  Eventually the coed division was ended to leave the current Boys and Girls divisions we have now.</p>
                            </Typography>
                            <Typography variant='h6'><p>Coed Championship History</p></Typography>
                            <ul>
                                <li>2003 - RSD defeated Bath on PK's</li>
                                <li>2004 - RSD defeated Bath 3-2</li>
                                <li>2005 - RSD defeated Intensity 2-1</li>
                                <li>2006 - Intensity defeated RSD 3-2</li>
                                <li>2007 - Intensity defeated ??</li>
                                <li>2008 - Intensity defeated ??</li>
                                <li>2011 - Intensity defeated ??</li>
                            </ul>
                        </Box>
                    }
                    {tabValue === 'Past Results' &&
                        <Box sx={{ paddingTop: '20px', width: '100%', maxWidth: '1200px'}}>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%'  }}>
                                <div>
                                    <Typography variant='h5'><p>Boys Championship Summary</p></Typography>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Team</TableCell>
                                                    <TableCell align='center'>Victories</TableCell>
                                                    <TableCell align='center'>Appearances</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {boysHistory.map((history) => (
                                                    <TableRow
                                                        key={history.team}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            {history.team}
                                                        </TableCell>
                                                        <TableCell align='center'>{history.victories}</TableCell>
                                                        <TableCell align='center'>{history.appearances}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Typography variant='h6' sx={{paddingTop: '10px'}}><p>Boys Championship History</p></Typography>
                                    <ul style={{ textAlign: 'left'}}>
                                        <li>2022 - Rapids defeated Cornerstone 2-1.</li>
                                        <li>2021 - Rapids defeated Cornerstone 7-0.</li>
                                        <li>2020 - Rapids defeated Scarlet Knights 3-0.</li>
                                        <li>2019 - Rapids defeated Scarlet Knights 2-0.</li>
                                        <li>2018 - Rapids defeated Scarlet Knights 3-0.</li>
                                        <li>2017 - Freedom Academy defeated Rapids 2-1.</li>
                                        <li>2016 - Rapids defeated Scarlet Knights 5-0.</li>
                                        <li>2015 - Rapids defeated RSD 3-2.</li>
                                        <li>2014 - Rapids defeated Freedom Academy 3-0.</li>
                                        <li>2013 - RSD defeated Freedom Academy 1-0 in overtime.</li>
                                        <li>2012 - Scarlet Knights defeated RSD 2-1</li>
                                        <li>2011 - RSD defeated USA 4-3</li>
                                        <li>2010 - Rapids defeated ??</li>
                                        <li>2009 - Scarlet Knights defeated RSD 3-0.</li>
                                        <li>2008 - Scarlet Knights defeated ??</li>
                                    </ul>
                                </div>
                                <div>
                                    <Typography variant='h5'><p>Girls Championship Summary</p></Typography>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Team</TableCell>
                                                    <TableCell align='center'>Victories</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {girlsHistory.map((history) => (
                                                    <TableRow
                                                        key={history.team}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                        <TableCell component="th" scope="row">
                                                            {history.team}
                                                        </TableCell>
                                                        <TableCell align='center'>{history.victories}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Typography variant='h6' sx={{paddingTop: '10px'}}><p>Girls Championship History</p></Typography>
                                    <ul style={{ textAlign: 'left'}}>
                                        <li>2022 - Rapids defeated Cornerstone 1-0.</li>
                                        <li>2021 - Cornerstone defeated Rapids in penalty kicks.</li>
                                        <li>2020 - Rapids defeated Cornerstone 1-0.</li>
                                        <li>2019 - Rapids defeated Scarlet Knights 1-0.</li>
                                        <li>2018 - Rapids defeated Scarlet Knights 2-0.</li>
                                        <li>2017 - Rapids</li>
                                        <li>2016 - Scarlet Knights</li>
                                        <li>2015 - Scarlet Knights</li>
                                        <li>2014 - Scarlet Knights</li>
                                        <li>2013 - Rapids</li>
                                        <li>2012 - Scarlet Knights</li>
                                        <li>2011 - Scarlet Knights</li>
                                        <li>2010 - Scarlet Knights</li>
                                        <li>2009 - Scarlet Knights</li>
                                        <li>2008 - Rapids</li>
                                        <li>2007 - Scarlet Knights</li>
                                        <li>2006 - Rapids</li>
                                        <li>2005 - Scarlet Knights</li>

                                    </ul>
                                </div>
                            </Box>
                        </Box>
                    }
                    {tabValue === 'About Us' &&
                        <Box sx={{ textAlign: 'left', maxWidth: '900px', paddingTop: '20px', paddingLeft: { xs: '10px', md: '40px' }, paddingRight: { xs: '10px', md: '40px' } }}>
                            <Typography variant='h5'><p>About Us</p></Typography>
                            <Typography variant='body1'>
                                <p>The Western New York Christian Athletic Association is a group of 7 clubs and schools that compete in interscholastic sports.  The league was formed over 35 years ago to allow smaller Christian Schools and clubs to participate in high school sports.</p>
                                <p>The current members are:</p>
                            </Typography>
                            <ul>
                                <li>Cornerstone Christian Academy</li>
                                <li>Intensity</li>
                                <li>Lima Christian School</li>
                                <li>Rochester Rapids</li>
                                <li>Rochester School for the Deaf</li>
                                <li>Scarlet Knights</li>
                                <li>Union Springs</li>
                            </ul>
                            <Typography variant='h6'><p>Soccer</p></Typography>
                            <Typography variant='body1'>
                                <p>Soccer is a primary sport for the WNYCAA.  We run a league from early September through the middle of October, often with multiple skill divisions to allow for competitive play.  After the regular season, there is a year end Tournament in the middle of October that is based on seeding from league play.</p>
                                <p>There is a lot of information on this site about the policies (rules) of the WNYCAA league, the current standings, scores, and calendar, as well as past results and history.</p>
                                <p>The current facilitator of the league is Irv Salisbury, who can be reached at <a href='mailto:irv@greenGoby.com' style={{ color: 'white'}}>irv@greenGoby.com</a> or at <a href='tel:585-766-5103' style={{ color: 'white'}}>(585) 766-5103</a></p>
                            </Typography>
                        </Box>
                    }
                    {tabValue === null &&
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center', maxWidth: 'xl',
                            alignItems: 'center', alignContent: 'center', gap: { xs: '30px', md: '60px' }, paddingTop: '40px', paddingLeft: '10px', paddingRight: '10px'}}>
                            {logos.map((logo) => (
                                <Box sx={ { width: { xs: logo.width/2, md: logo.width }} }>
                                    <img src={logo.image} width="100%" />
                                </Box>
                            ))}
                        </Box>
                    }
                </Stack>
            </div>
        </ThemeProvider>
    );
}

export default App;
